import React from "react";
import styled from "styled-components";
import { Twitter, Facebook, Linkedin } from "react-feather";

const ScMain = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 4rem;

  h6 {
    font-weight: normal;
    font-size: 14px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
`;

const ScGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ScItem = styled.a`
  color: white;
  margin-left: 4px;
  margin-right: 4px;
  display: inline-block;
  padding: 8px;
`;

export const Share = ({ url, title }: { url: string; title: string }) => {
  return (
    <ScMain>
      <h6>Share</h6>
      <ScGroup>
        <ScItem
          href={`http://twitter.com/share?text=${title}&url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter size={16} />
        </ScItem>
        <ScItem
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${title}&source=https://power.trade/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Linkedin size={16} />
        </ScItem>
        <ScItem
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          
          <Facebook size={16} />
        </ScItem>
      </ScGroup>
    </ScMain>
  );
};
