import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { ArrowLeft } from "react-feather";
import Img from "gatsby-image";

import { Container } from "../components/Grid";
import { Layout } from "../components/Layout";
import {
  ScBackButton,
  ScCategoryText,
  ScContent,
  ScHeaderWrapper,
  ScHeader,
  ScMain,
  ScAuthor,
} from "./styled";
import { Share } from "./shared";
import { normalizeNotionFrontMatter } from "../utils/normalizeNotionBlog";
import removeMd from "remove-markdown";

const ScRoot = styled.div`
  background-color: var(--darkmode);
`;

const ScFeature = styled.div`
  margin-bottom: 3rem;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  img {
    max-width: 100%;
  }
`;

const PostTemplate = ({ data }: any) => {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter: frontmatterSrc, html } = markdownRemark;
  const frontmatter = normalizeNotionFrontMatter(frontmatterSrc);
  const cover =
    frontmatter.cover ||
    "https://assets-powertrade.surge.sh/content/images/2022/02/Roadmap-1.png";
  const link = `https://power.trade/blog/${frontmatter.slug}`;
  const title = removeMd(frontmatter.title);

  return (
    <Layout>
      <ScRoot>
        <Helmet titleTemplate="%s | Blog">
          <title>{title}</title>
          <meta property="og:image" content={cover} />
          <meta property="og:url" content={link} />
          <meta property="og:title" content={title} />
          <meta
            property="og:description"
            content={
              frontmatter.summary ||
              "Crypto Derivatives Exchange — Trade USD-settled BTC, ETH, and alt-coin options, perpetuals, futures and spot markets. Maximize your trading with our advanced RFQ, multi-leg strategies and portfolio margin system."
            }
          />
          <meta name="twitter:image" content={cover} />
          <meta name="twitter:url" content={link} />
          <meta name="twitter:title" content={title} />
          <meta
            name="twitter:description"
            content={
              frontmatter.summary ||
              "Crypto Derivatives Exchange — Trade USD-settled BTC, ETH, and alt-coin options, perpetuals, futures and spot markets. Maximize your trading with our advanced RFQ, multi-leg strategies and portfolio margin system."
            }
          />
        </Helmet>
        <Container>
          <ScMain>
            <ScBackButton to="/blog/">
              <ArrowLeft size={24} /> Back
            </ScBackButton>

            <ScCategoryText>
              {frontmatter.category} | {frontmatter.date}
            </ScCategoryText>

            <ScHeaderWrapper>
              <ScHeader>{title}</ScHeader>
              <ScAuthor>
                <img
                  src="/images/v5/blog/blog-pt-icon.png"
                  alt="PowerTrade Team"
                />
                <main>{frontmatter.author}</main>
              </ScAuthor>
            </ScHeaderWrapper>

            <ScFeature>
              <Img
                fluid={markdownRemark.featuredImg.childImageSharp.fluid}
                alt={title}
              />
            </ScFeature>

            <ScContent className="post-full-content">
              <div
                className="post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </ScContent>

            <Share
              title={title}
              url={`https://power.trade/blog/${frontmatter.slug}`}
            />
          </ScMain>
        </Container>
      </ScRoot>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      frontmatter {
        author {
          name
        }
        category {
          name
        }
        cover {
          file {
            url
          }
          name
        }
        publish_date {
          start(formatString: "MMMM DD, YYYY")
        }
        slug
        status {
          name
        }
        summary
        title
      }
    }
  }
`;
